import React from "react";
import Header from "../../Packages/Header";
import Footer from "../../Packages/Footer";
import ModalBox from "../../Packages/ModalBox";
import bg2 from "../../assets/Images/bg2.png";
import { Helmet } from 'react-helmet';

const div = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const Login = () => {
  return (
    <div>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div
        style={{
          backgroundImage: `url(${bg2})`,
          backgroundSize: "cover",
          width: "100%",
          height: "233px",
          marginBottom: "50px",
        }}
      >
        <Header />
        <p className="headingOne">Login</p>
      </div>
      <div style={div}>
        <ModalBox
          headingPresent={false}
          purchase={false}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Login;
