import React, { useState, useEffect } from "react";
import { checkUser, removeUser } from "../../components/Scripts/UnsubscribeApi";
import "../../Packages/UnsubscribeBox/style.css";

function UnsubscibeBox() {
  const [stepEmail, setStepEmail] = useState(true);
  const [stepOtp, setStepOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidCode, setIsValidCode] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [code, setCode] = useState("");
  const [getcode, setGetcode] = useState("");
  const [notUser, setNotUser] = useState(false);

  useEffect(() => {
    const clearOtpAfterTimeout = () => {
      setCode("");
    };
    setTimeout(clearOtpAfterTimeout, 600000);
  }, [code]);

  const handleClick = async () => {
    if (stepEmail) {
      if (email.trim() === "") {
        setErrorMessage("This field is required!");
        // console.log("empty");
        setIsValidEmail(false);
      } else {
        // console.warn(email);
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const isValid = regex.test(email);
        setIsValidEmail(isValid);
        if (!isValid) {
          setErrorMessage("Please enter a valid email address.");
        } else {
          const data = await checkUser(email);
          console.log(data);
          console.log(data.user_data);
          const resdata = data.user_data;
          if (resdata.length === 0) {
            setMessage(
              <div className="text-base font-pop600 text-darkTeal text-center">
                You are already unsubscribed!
              </div>
            );
            setStepEmail(false);
            setNotUser(true);
          } else {
            // console.log(data.code);
            const resCode = data.code;
            setCode(resCode);
            setStepEmail(false);
            setStepOtp(true);
          }
        }
      }
    } else {
      // console.warn(code);

      if (getcode.trim() === "") {
        setErrorMessage("This field is required!");
        // console.log("empty code input box");
        setIsValidCode(false);
      } else {
        if (code !== getcode) {
          // console.log("code -->" , code);
          // console.log("code -->" , getcode);
          setErrorMessage(
            "This verification code is incorrect."
          );
          setIsValidCode(false);
        } else {
          const resdata = await removeUser(email);
          console.log(resdata);
          console.log(resdata.statusCode);
          const stscode = resdata.statusCode;
          if (stscode === 200) {
            setMessage(
              <div className="text-base font-pop600 text-darkTeal">
                Your records are deleted from our system.
                <br />
                Thank you for using NiADA.
              </div>
            );
            setStepOtp(false);
            setNotUser(true);
          } else {
            alert("Opps something went wrong!");
          }
        }
      }
    }
  };
  return (
    <div className="myBox">
      {stepEmail && (
        <div>
          {/* <div className="header">
            <p className="heading">Unsubscribe from Monere</p>
          </div> */}
          <div className="body">
            <p className="lable">E-mail Address:</p>
            <input
              placeholder="Enter your e-mail address"
              onChange={(i) => setEmail(i.target.value)}
              className="inputBox"
            />
            {!isValidEmail && (
              <small className="errorMsg">{errorMessage}</small>
            )}
          </div>
        </div>
      )}

      {stepOtp && (
        <div>
          {/* <div className="header">
            <p className="heading">Unsubscribe from Monere</p>
          </div> */}
          <div className="body">
            <p className="lable">E-mail Address:</p>
            <p className="lable text-primeBlue">{email}</p>
            <p className="lable mt-8">
              Enter the Verification code sent to your e-mail:
            </p>
            <input
              placeholder="Verification code"
              onChange={(v) => setGetcode(v.target.value)}
              className="inputBox"
            />
            {!isValidCode && <small className="errorMsg">{errorMessage}</small>}
          </div>
        </div>
      )}

      {stepEmail && (
        <div className="footer">
          <button className="btn" onClick={handleClick}>
            Continue
          </button>
        </div>
      )}

      {stepOtp && (
        <div className="footer">
          <button className="btn" onClick={handleClick}>
            Verify
          </button>
        </div>
      )}

      {notUser && (
        <div>
          <div className="body">
            <p className="text-center mt-8 text-base font-pop500">{message}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default UnsubscibeBox;
