import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
// import { createUser, verifyUser } from "../Scripts/Api";
// import WarningIcon from "@mui/icons-material/Warning";
// import DoneIcon from "@mui/icons-material/Done";
// import PlanTypeMenu from "../PlanTypeMenu";
import Header from "../../Packages/Header";
import Footer from "../../Packages/Footer";
import CustomModal from "../../components/CustomModal";
import UnsubscribeBox from "../../Packages/UnsubscribeBox";
import bg2 from "../../assets/Images/bg2.png";
import { Helmet } from 'react-helmet';


const div = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const NewUnsubscribe = () => {
  return (
    <div>
      <Helmet>
        <title>Unsubscribe</title>
      </Helmet>
      <div
        style={{
          backgroundImage: `url(${bg2})`,
          backgroundSize: "cover",
          width: "100%",
          height: "233px",
          marginBottom: "50px",
        }}
      >
        <Header />
        <Typography className="headingOne">Unsubscribe</Typography>
      </div>
      <div style={div}>
      <UnsubscribeBox />
      </div>
      <Footer />
    </div>
  );
};

export default NewUnsubscribe;
